import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import Hero from "../components/Blog/Hero"
import ArticleList from "../components/Blog/ArticleList"
import PageContents from "../components/Blog/PageContents"
import { sortArticles } from "../services/blog/sort"

const BlogTemplate = (props) => {
  const { data, location, pageContext } = props
  const path = location.pathname
  const { page, desktopArticles, mobileArticles, categories } = data
  const { currentPage, totalPages } = pageContext
  const { blocks } = page

  let hero

  blocks.forEach((block) => {
    if (block.__typename === "ContentfulHeroBlock") {
      hero = block
    }
  })

  return (
    <Layout>
      <Seo
        title={page.metaTitle}
        description={page.metaDescription}
        path={path}
      />
      {hero && <Hero {...hero} />}
      <ArticleList
        desktopArticles={sortArticles(desktopArticles.nodes, "blogOrder")}
        mobileArticles={sortArticles(mobileArticles.nodes, "blogOrder")}
        categories={categories.nodes}
        currentPage={currentPage}
        totalPages={totalPages}
      />
      <PageContents blocks={blocks} />
    </Layout>
  )
}

export default BlogTemplate

export const pageQuery = graphql`
  query BlogQuery($mobileArticles: [String]) {
    page: contentfulPage(slug: { eq: "blog" }) {
      id
      slug
      metaTitle
      metaDescription
      blocks {
        __typename
        ... on ContentfulHeroBlock {
          header
          subText {
            raw
          }
          ctaLink
          ctaLabel
          hero {
            gatsbyImageData(width: 1920)
          }
          mobileHero {
            gatsbyImageData(width: 1202)
          }
        }
        ... on ContentfulProductHighlightBlock {
          id
          ctaLabel
          introContent {
            raw
          }
          product {
            productSlug
          }
        }
        ... on ContentfulCarouselBlock {
          id
          carouselHeader {
            raw
          }
          ctaLabel
          ctaLink
          ctaPosition
          slides {
            __typename
            ... on ContentfulCategory {
              id
              slug
              title
              categoryColor
              shortDescription {
                childMarkdownRemark {
                  html
                }
              }
              thumbnail {
                gatsbyImageData(width: 716)
              }
            }
          }
        }
      }
    }
    categories: allContentfulCategory(sort: { fields: slug }) {
      nodes {
        categoryColor
        title
        slug
      }
    }
    desktopArticles: allContentfulArticle(sort: { fields: date, order: DESC }) {
      nodes {
        category {
          title
          slug
          categoryColor
        }
        blogOrder
        tags
        slug
        title
        ...articleBodyFragment
        thumbnail {
          gatsbyImageData(width: 636, aspectRatio: 1)
        }
      }
    }
    mobileArticles: allContentfulArticle(
      filter: { slug: { in: $mobileArticles } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        category {
          title
          slug
          categoryColor
        }
        blogOrder
        tags
        slug
        title
        ...articleBodyFragment
        thumbnail {
          gatsbyImageData(width: 636, aspectRatio: 1)
        }
      }
    }
  }
`
